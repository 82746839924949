import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'

const Header = styled.h1`
  text-align: center;
  color: #2e3e4f;
  text-transform: uppercase;
  margin-top: 1.5em;
`

const Value = styled.div`
  h2 {
    color: #444;
    margin-top: 1.5em;
  }
  img {
    width: 200px;
  }
`

const ExternalLink = styled.a`
  /* background-color: rgba(237, 194, 157, 0.7); */
  box-shadow: 0 2px 0 rgba(237, 194, 157, 1); /* #2e3e4f */
  color: #000;
  padding: 0.2em;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    background-color: rgba(237, 194, 157, 0.4);
  }
`

function IndexPage() {
  return <Layout>
      <div>
        <Header id="who-we-are">Who we are</Header>
        <p>
          Established in 2016, Raizean is a multi-channel E-commerce company.
        </p>
        <p>
          We currently sell direct-to-consumer through our partnered websites
          Amazon and eBay.
        </p>
        <p>
          With the rapid rate at which e-commerce is growing globally, we
          believe Raizean is the best method for your products to be sold in
          all the right places online.
        </p>
      </div>
      <div>
        <Header id="what-we-do">What we do</Header>
        <Value>
          <h3>Provide E-commerce Peace</h3>
          <p>
            The online marketplace can be a confusing place. At Raizean, we
            aim to make your life easier by selling your products online
            taking the responsibility away from you.
          </p>
        </Value>
        <Value>
          <h3>Instant Payment</h3>
          <p>
            We pay for products upfront helping our partner’s cash flow and
            making the products our responsibility. We do not return any
            purchase. When Raizean purchases a product, we keep the product.
          </p>
        </Value>
        <Value>
          <h3>Search Engine Optimisation</h3>
          <p>
            We endeavour to give your brand better visibility and reviews on
            online marketplaces by creating optimized listings and driving
            traffic to your product through Search Engine Optimization (SEO)
            using Amazon’s in-house Pay Per Click campaign’s (PPC).
          </p>
        </Value>
        <Value>
          <h3>Brand Integrity</h3>
          <p>
            The status of your brand is the heart of your success on any
            marketplace. It is our aim to ensure that your brand is correctly
            represented on the online marketplace.
          </p>
        </Value>
        {/* <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
          <Image />
        </div> */}
      </div>
      <div>
        <Header id="contact">Contact us</Header>
        <p>
          For partnership enquires please contact us at <ExternalLink href="mailto:info@raizean.com">
            info@raizean.com
          </ExternalLink>
        </p>
        {/* <form action="">
          <label htmlFor="formName" />
          <input type="text" name="formName" id="formName" />
        </form> */}
      </div>
    </Layout>
}

export default IndexPage
